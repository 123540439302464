// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-posts-js": () => import("./../../src/pages/posts.js" /* webpackChunkName: "component---src-pages-posts-js" */),
  "component---src-templates-category-template-js": () => import("./../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-noticias-article-print-template-js": () => import("./../../src/templates/noticias/article-print-template.js" /* webpackChunkName: "component---src-templates-noticias-article-print-template-js" */),
  "component---src-templates-noticias-article-template-js": () => import("./../../src/templates/noticias/article-template.js" /* webpackChunkName: "component---src-templates-noticias-article-template-js" */),
  "component---src-templates-noticias-categoria-template-js": () => import("./../../src/templates/noticias/categoria-template.js" /* webpackChunkName: "component---src-templates-noticias-categoria-template-js" */),
  "component---src-templates-noticias-topic-template-js": () => import("./../../src/templates/noticias/topic-template.js" /* webpackChunkName: "component---src-templates-noticias-topic-template-js" */),
  "component---src-templates-post-template-js": () => import("./../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

